.YearHeader {
    padding: 10pt;
    text-align: center;
}

.YearHeader > h1 {
    font-weight: 650;
}

@media (max-width: 767px) {
    .YearHeader > h1 {
        font-size: 160%;
    }   

    .YearHeader > h3 {
        font-size: 130%;
    }

    .YearHeader {
        padding-bottom: 5pt;
    }
}

.WinnerBadge {
    max-height: 150px;
}

.NoTopPadding {
    margin-top: 0;
    padding-top: 0;
}
