.RunnerUpLabel {
    font-style: italic;
    font-size: 70%;
    /* vertical-align: 15%; */
    margin-right: 8px;
}

/* @media (max-width: 767px) {
    .RunnerUp {
        margin-top: 5pt;
    }
} */

.RunnerUp {
    margin-left: 15pt;
    margin-right: 15pt;
}
