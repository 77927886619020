:root {
  color-scheme: light dark;
  --jumbotron-color: #E9ECEF;
  --body-text: #212429;
  --body-background: #ffffff;
  --button-color: #
}

@media (prefers-color-scheme: dark) {
  :root {
    --jumbotron-color: #292929;
    --body-text: #e6e6e6;
    --body-background: #1f1f1f;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--body-text);
  background-color: var(--body-background);
}

.jumbotron {
  background-color: var(--jumbotron-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
