.App {
  min-height: 100vh;
}

@media (max-width: 767px) {
  .Navbar-Title-Brand {
    font-size: 140%;
    font-weight: 800;
  }

  .Navbar-Title-Img {
    padding-left: -11px;
    width: 60px;
  }
}

@media (min-width: 768px) {
  .Navbar-Title-Brand {
    font-size: 250%;
    font-weight: 800;
  }

  .Navbar-Title-Img {
    padding-left: -11px;
    width: 80px;
  }
}

.Navbar-Title {
  margin-top: 0;
  padding-top: 0;
}

.Navbar-Title-Main {
  margin-top: 0;
  padding-top: 0;
}

.Navbar-Title-Brand {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.NavbarTitleBrand:hover .Navbar-Title-Img {
  box-shadow: 0px 0px 25px black;
  transition: box-shadow 0.2s ease-in-out;
}

.NavbarTitleBrand:hover .NavbarTitleText {
  text-shadow: 0px 0px 25px black;
  transition: text-shadow 0.2s ease-in-out;
}

.Navbar-Year-Brand {
  font-weight: 400;
}

.Navbar-Links {
  font-weight: 700;
}

@media (max-width: 767px) {
  .TextCenter {
    justify-content: center;
    text-align: center;
  }
}

.YearLinks > a {
  text-decoration-line: underline !important;
}

.Footer {
  padding-top: 15px;
  padding-bottom: calc(15px + env(safe-area-inset-bottom));
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0px;
  text-align: center;
}
