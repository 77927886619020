.LandingButton {
    margin-right: 10px;
    margin-bottom: 25px;
}

.Hero {
    width: 100%;
}

.WelcomeHeader {
    font-weight: 800;
    text-align: center;
    font-size: 280%;
}

.WelcomeHelperText {
    font-weight: 600;
    font-size: 120%;
    text-align: center;
}

.WelcomeButton {
    margin-top: 15px;
    margin-right: 10px;
}

.LandingArtwork {
    display: block;
    margin: 0 auto;
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 10px;
}

.LandingArtworkSmall {
    display: block;
    margin: 0 auto;
    max-width: 250px;
    max-height: 250px;
    margin-bottom: 10px;
}

.WelcomeBox {
    margin-bottom: 0px;
}

.ListenToUpgrade {
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.ListenToHeading {
    font-weight: 700;
}
