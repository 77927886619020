.Award {
  max-width: 900px;
  padding-bottom: 35pt;
}

@media (min-width: 0px) {
  .CategoryHeading {
    justify-content: center;
    text-align: center;
  } 
}

@media (min-width: 576px) {
  .CategoryNameAndSubname {
    margin-top: 18px !important;
  }

  .CategoryNameOnly {
    margin-top: 30px !important;
  }
}



.CategoryName {
  font-weight: 700;
  margin-bottom: 10px !important;
}

.CategorySubname {
  font-weight: 500;
  margin-top: -10px !important;
  margin-bottom: 15px !important;
}


@media (min-width: 576px) {
  .CategoryName {
    font-weight: 700;
  }
  
  .CategorySubname {
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .CategoryName {
    font-size: 180%;
    margin-left: 15pt;
    margin-right: 15pt;
  }

  .CategorySubname {
    font-size: 120%;
    margin-left: 15pt;
    margin-right: 15pt;
  }

  .WinnerImage {
    /* display: block; */
    width: 260px;
    margin-bottom: 10px;
  }
}

@media (max-width: 899px) {
  .CategoryName {
    margin-left: 15pt;
  } 

  .CategorySubname {
    margin-left: 15pt;
  } 

  .WinnerImage {
    width: 250px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
    .WinnerInfo {
      display: block;
      justify-content: center;
      margin-left: 15pt;
      margin-right: 15pt;
    }
}

.AppIcon {
  display: block;
  height: 250px;
  border-radius: 22.5%;
  margin-top: 7px;
  margin-bottom: 10px;
}

@media (min-width: 900px) {
  .WinnerImage {
      width: 280px;
      margin-bottom: 10px;
  }
}


.YearBadge {
  display: flex !important;
  max-height: 120px;
  margin-bottom: 8px;
}

@media (max-width: 575px) {
  .AnchorHeading {
    display: none;
  }
}

@media (min-width: 576px) {
  .AnchorHeading {
    font-size: 45%;
  }
}

.WinnerAbove {
  font-size: 16pt;
  font-style: italic;
}

.WinnerName {
  font-size: 36pt;
  font-weight: 700;
}

@media (max-width: 768px) {
  .WinnerName {
    font-size: 34pt;
    font-weight: 700;
  }

  .WinnerAuthor {
    margin-bottom: 15pt;
  }
}

@media (min-width: 768px) {
  .WinnerAbove {
    text-align: left;
  }

  .WinnerName {
    text-align: left;
  }

  .WinnerAuthor {
    font-size: 24pt;
    margin-bottom: 15pt;
  }
}
